import React from 'react';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';
import ImgCollage from './assets/pocollage.webp';
import ImgHeader from './assets/back_about.jpg';

const Styles = styled.div`
    .row {
        margin-left: 0px;
        margin-right: 0px;
        font-family: 'Montserrat', sans-serif;
    }
`;

const PStyled = styled.p`
    font-size: 16px !important;
    margin-bottom: 20px;
`;

export const About = () => {
  return (
    <Styles>
      <CustomJumbotron bgimage={ImgHeader}>ABOUT</CustomJumbotron>
      <Container style = {{paddingTop: 20, paddingBottom: 80}}>
        <Row>
          <Col xs={12} lg={6} style = {{marginTop: 65}}>
            <h2 style = {{fontWeight: 'bold', marginBottom: 20}}>Biographical Sketch</h2>
            <PStyled>
              Po-Shen Loh is a social entrepreneur and mathematician, with
              a track record of inventing incentive-aligned solutions to
              address timely population-scale real-world problems, from <a
              href="https://www.straitstimes.com/tech/tech-news/us-prof-keen-to-work-with-spore-on-app-that-warns-users-before-they-are-exposed-to">pandemic
              control</a> to helping human society thrive in the AI era. He
              is a math professor at Carnegie Mellon University, and served
              a decade-long term as the national coach of the USA
              International Mathematical Olympiad team from 2013–2023.
              Since 2023, he has been the Vice President of the IMO
              Foundation, as the founder and organizer of the annual IMO
                Alumni Reunion. His latest innovation <a
              href="https://www.cnn.com/world/professor-po-shen-loh-actors-classrooms-spc">brings
              together math stars and professional actors</a>, to
              mass-produce live-streamed creative problem-solving lessons
              that match the engagement level of online video entertainment.
            </PStyled>
            <PStyled>
              As an academic, Po-Shen has earned distinctions ranging from
              an International Mathematical Olympiad silver medal to the <a
              href="https://www.whitehouse.gov/briefings-statements/president-donald-j-trump-announces-recipients-presidential-early-career-award-scientists-engineers/">United
                States Presidential Early Career Award for Scientists and
                Engineers</a>. His pure scientific research considered a
              variety of questions that lie at the intersection of
              combinatorics (the study of discrete systems), probability
              theory, and computer science. As an educator, he was the
              coach of Carnegie Mellon University’s math team when it
              achieved its <a
              href="https://www.cmu.edu/news/stories/archives/2017/april/putnam-mathematics-competition.html">first-ever
                #1 rank</a> among all North American universities, and the
                coach of the USA Math Olympiad team when it achieved its <a
              href="https://www.cbsnews.com/news/americas-best-young-math-minds-compete-in-rio/">first-ever
                back-to-back #1-rank victories</a> in 2015 and 2016, and
                then again in 2018 and 2019. His research and educational
              outreach takes him to cities across the world, reaching over
              10,000 people each year through <a href="/tour">public
              lectures and events</a>, and he has <a
                href="https://www.youtube.com/playlist?list=PLqv4sKOD1bsUoSs-SbzlA2BE1tML4A33u">featured
                in or co-created videos totaling over 24 million YouTube
                views</a>.
            </PStyled>
            <PStyled>
              Po-Shen received his undergraduate degree in mathematics
              from <a
                href="https://www.caltech.edu/about/news/caltech-student-receives-multiple-prestigious-graduate-honors-827">Caltech</a> in
              2004, graduating with the highest GPA in his class. He received a
              master’s degree in mathematics from the University of
              Cambridge in 2005, where he was supported by a Winston
                Churchill Foundation Scholarship. He continued his studies
              at <a
                href="https://paw.princeton.edu/article/professor-and-coach-po-shen-loh-10-challenges-rising-mathematicians">Princeton</a>,
                supported by a <a
              href="https://hertzfoundation.org/fellows/fellow-profile/10187/Po-Shen-Loh">Hertz
              Foundation Fellowship</a> and a National Science Foundation
              Graduate Research Fellowship, where he completed his Ph.D. in
              mathematics at the end of 2009, and has been on the faculty
              at Carnegie Mellon University ever since.
            </PStyled>
          </Col>
          <Col className="d-none d-sm-block" xs={12} lg={6} style = {{marginTop: 100}}>
            <img src={ImgCollage} alt="Po-Shen Loh speaking" style={{maxWidth: "100%"}}></img>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </Styles>
  );
}
